@import url("https://api.mapbox.com/mapbox-gl-js/v1.9.0/mapbox-gl.css");

.mapboxgl-map {
  /* position: unset !important; */
  width: 100%;
  max-height: 66vh;
  height: 100vh;
  margin: 0 auto;
  background: grey;
  border-radius: 8px 0px 8px 0px;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-ctrl-top-right {
  position: unset !important;
}

.mapboxgl-canvas {
  border-radius: 8px 8px 8px 8px;
}

.marker {
  /* background-image: url('https://i.imgur.com/MK4NUzI.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer; */
}

/* body { margin:0; padding:0; }
#map { position:absolute; top:0; bottom:0; width:100%; } */

/* .chartWrapper {
  position: relative;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  width: 1500px;
  height: '5em';
  overflow-x: scroll;
} */

/* .scroll-horizental-histo {
  overflow-x: scroll;
} */

.highcharts-credits {
  display: none;
}

/* .legend {
  background-color: #fff;
  border-radius: 3px;
  bottom: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  position: absolute;
  right: 10px;
  z-index: 1;
  }
   
  .legend h4 {
  margin: 0 0 10px;
  }
   
  .legend div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
  } */

body {
  margin: 0;
  padding: 0;
}
#map {
  top: 0;
  bottom: 0;
  width: 100%;
}

.legend {
  background-color: #fff;
  border-radius: 3px;
  bottom: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 10px;
  right: 10px;
  z-index: 1;
  position: relative;
  bottom: 10em;
  left: 1em;
  width: 8em;
}

.legend h4 {
  margin: 0 0 10px;
}

.legend div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}

.halt_marker {
  background-image: url("https://assetsstatic.s3.ap-south-1.amazonaws.com/analytics_dashboard/halt.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.source_marker{
  background-image: url("https://assetsstatic.s3.ap-south-1.amazonaws.com/analytics_dashboard/warehouse.svg");
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.destination_marker{
  background-image: url("https://assetsstatic.s3.ap-south-1.amazonaws.com/analytics_dashboard/destination.svg");
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
